
.App{
  display:grid;
  grid-template-rows: auto;
    width:100%;
    min-height:100vh;
    object-fit: cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../src/assets/banner_2.webp");
    background-position: center;
    background-size: cover;
}

.Main, .Contact_Me, .About_Me{  
  padding: 0;
  margin: 0;
  width: 100%;
    min-height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

 }

 .main_content_container, .about_me_container, .contact_me_container{
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: 0;
 }

.Main{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../src/assets/main_intro_background.webp"); 
    border: 1.5px solid aqua; 
}
.Contact_Me{

  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url("../src/assets/contact_me.webp");
}



.about_me_info{
  color: white;
}
.hero_text {
    text-align: center;
    color: white;
    padding:0;
    margin: 0;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .Intro{ 
    display: grid;
    grid-template-rows: auto;
    
  }

  .Name{
    text-shadow: 1px 2px #00897b;
    color:white;
    font-family: 'Brush Script MT', cursive;
    font-size: 6rem;
    border: 0;
    padding: 0;
    margin: 0;
  
  }

  .Title{
    margin: 0 0 5% 0;
    color:#64ffda;
    font-size: 80%;
font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
text-shadow: 2px 2px black;
border-bottom: 1px solid white ;

  }
 

  .title_container{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hire_me_button_container{
    display: flex;
    justify-content: center;
    border: 0;
    margin: 0;
    padding: 0;

  }

  .main_container{
    display: flex;
    justify-content: center;
    border: 0;
    padding: 0;
    margin: 0;
  }


  .about_me_title{
    color:#00bfa5;
  
    text-shadow: 1px 2px black;
    font-size: 1.75rem;
    
  }
  .about_me_info_container{
    display: grid;
    grid-template-rows: auto;
  }

  .about_me_info{
    color: white;
    font-family: 'monospace';
    text-shadow: 1px 2px black;
    font-size: 1rem;
  }

  .neonText{
    font-family:"Brush Script MT", cursive;
    font-size:3rem ;
    display:flex;
    justify-content:center;
    color: white;
    text-shadow:0 1px aqua;
   
  }
.eric_romero{
  color:aqua;
  font-size: 1.15rem;
}
  .frontEnd{
    font-size:0.75rem;
    color:white;
    text-shadow:
    0 0 1px #00bfa5,
    0 0 11px #00bfa5,
    0 0 22px #00bfa5,
    0 0 33px #00bfa5,
    0 0 44px #00bfa5,
    0 0 55px #00bfa5,
    0 0 65px #00bfa5,
    0 0 77px #00bfa5;
  }

  .pulsate{
    animation: pulsate 1.5s infinite alternate;  
  }
  @keyframes pulsate {
    
    100% {
  
        text-shadow:
        0 0 4px #fff,
        0 0 11px #fff,
        0 0 19px #fff,
        0 0 40px #00bfa5,
        0 0 80px #00bfa5,
        0 0 90px #00bfa5,
        0 0 100px #00bfa5,
        0 0 150px #00bfa5;
    
    }
    
    0% {
  
      text-shadow:
      0 0 2px #00bfa5,
      0 0 4px #00bfa5,
      0 0 6px #00bfa5,
      0 0 10px #00bfa5,
      0 0 45px #00bfa5,
      0 0 55px #00bfa5,
      0 0 70px #00bfa5,
      0 0 80px #00bfa5;
  
  }}

  .accordion_container{
    padding:0;
    margin:0;
    border:0;
  }
  .accordionList{
    font-size:1rem;
    padding:0;
    margin:0;
    border:0;
    color:white;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
 
  }
  .accordionItems{
    padding:0.25rem 0 ;
  }

  .accordionHighlight{
    color: yellow;
    text-shadow:
    0 0 1px white,
    0 0 2px black,
    0 0 3px black,
    0 0 4px black,
    0 0 4px black,
    0 0 4px black,
    0 0 4px yellow,
    0 0 4px white;
  }


  .eric_bio_container{
   color:white;
  }

  .eric_bio_pic{
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../src/assets/about_me.webp");
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5%;
    display: flex;
    justify-content: center;
    border:1px solid rgba(225, 255, 255, 0.3);
  }
  .eric_bio{
    display: flex;
    justify-content: center;
    align-items: center;
  }
.tech_intro{
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  justify-content: center;
}
  .badges{
    display: flex;
    justify-content: center;
    background: rgba(0, 255, 255, 0.1);
    backdrop-filter: blur(2px);
    border-radius: 8px;
    box-shadow: 0 0 5px 1px white,0 0 1px 1px aqua;
    font-size: 0.8rem;
    color:white;
    padding:2.5% 2.5%;
  

  }
.neon_hr{ 
  width: 100%;
  position: absolute;
  background-color: aqua;
  box-shadow: 0px 0px 12px aqua;
  height: 2px;
  border-radius: 50%;
  overflow: hidden;
}


.contact_him_container{
  display:grid;
  grid-template-rows: auto;
  padding-top:1rem;
  margin:0;
  border:0;
}

.contact_him_title{
  margin-top:1rem;
  font-size:1.1rem;
  display: flex;
  justify-content: center;
  color: white;
  text-shadow:
  0 0 8px black,
  0 0 2px black,
  0 0 3px black,
  0 0 4px black,
  0 0 4px aqua,
  0 0 4px aqua,
  0 0 4px aqua,
  0 0 4px aqua;
}
.contact_him{
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactButton{
    font-weight: bold;
    padding: 3% 7%;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 8px;
    display: block;
    border: 0px;
    font-size:80%;
    text-shadow:1px 1px black;
    box-shadow: 0px 0px 15px -7px aqua;
    background-image: linear-gradient(45deg, black 0%, gray  51%, black  100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .contactButton:hover {
    background-position: right center;
    color: aqua;
    text-decoration: none;
    text-shadow:1px 1px black;  
  }
  
  .contactButton:active {
    transform: scale(0.95);
  }

  .hire_me_button{
    font-weight: bold;
    padding: 4% 7%;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 15px;
    display: block;
    border: 0px;
    font-size:80%;
    text-shadow:1px 1px black;
    box-shadow: 0px 0px 25px 5px black;
    background-image: linear-gradient(45deg, black 0%, #00bfa5 51%, black  100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .hire_me_button:hover {
    background-position: right center;
    color: black;
    text-decoration: none;
    text-shadow: 1px 1px white;
    box-shadow: 0px 0px 25px -2px aqua;
  }
  
  .hire_me_button:active {
    transform: scale(0.95);
  }


.emoji{
  font-size: 1.2rem;
  text-shadow:
  0 0 1px black,
  0 0 2px  aqua,
  0 0 3px white,
  0 0 4px black,
  0 0 5px black,
  0 0 6px black,
  0 0 7px aqua,
  0 0 8px black;
}

.contact_me_content{
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  padding:0;
  margin:0;
  border:0;
}
.contact_me_intro_container{
  display: flex;
  justify-content:center;
  width: 100%;
  padding-top:1.5rem;
  padding-left: 1rem;
  border: 0;
  margin: 0;
  height: 2px;
}

.contact_me_intro{
  display: flex;
  width: 100%;
  justify-content: start;
  padding: 0;
  margin:0;
  border:0;  
}

.contact_me_highlights{
  font-weight:600;
  font-size: 1.5rem;
  color: black;
 
  text-shadow:
  0 0 1px #00bfa5,
  0 0 11px #00bfa5,
  0 0 22px #00bfa5,
  0 0 33px #00bfa5,
  0 0 44px #00bfa5,
  0 0 55px #00bfa5,
  0 0 65px #00bfa5,
  0 0 77px #00bfa5;
}

.neon_hr_contact_me{ 
  width: 100%;
  background-color: aqua;
  box-shadow: 0px 0px 12px aqua;
  height: 2px;
  border-radius: 50%;
 padding: 0;
 margin: 0;

}

.contact_me_info{
  display: flex;
  justify-content: center;
  width: 100%;
  color:white;
}

.resume{

  margin: 0 0.5rem;
  padding: 3% 7%;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 5px;
  display: block;
  border: 0px;
  font-size:70%;
  text-shadow:1px 1px black;
  box-shadow: 0px 0px 15px -7px aqua;
  background-image: linear-gradient(45deg, black 0%, gray  51%, black  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.resume:hover {
  background-position: right center;
  color: aqua;
  text-decoration: none;
  text-shadow:1px 1px black;  
}

.resume:active {
  transform: scale(0.95);
}

.contact_info{
  padding:0;
  border:0;
  margin:0;
}
.label{
  color:#00897b;
  text-shadow:1px 1px black;
}

.contact_info_container{
  padding-left:1rem;
  display: grid;
  grid-template-rows: auto;
  gap:1rem
}

.contact_me_forms_container{
  width: 100%;
  display: flex;
  justify-content: center;
  
}


.contact_parenthesis{
  color:#00897b;
}

.portfolio_test{
  font-weight: bold;
  margin: 0 0.5rem;
  padding: 3% 7%;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 8px;
  display: block;
  border: 0px;
  font-size:70%;
  text-shadow:1px 1px black;
  box-shadow: 0px 0px 25px 2px black;
  background-image: linear-gradient(45deg, black 0%, #FFD700  51%, black  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.portfolio_test:hover {
  background-position: right center;
  color: white;
  text-decoration: none;
  text-shadow:1px 1px black;  
  box-shadow: 0px 0px 25px -7px yellow;
}

.portfolio_test:active {
  transform: scale(0.95);
}

.socials_container{
  padding-left:1rem;
  display: grid;
  grid-template-rows: auto;
  gap:2rem
}

.portfolio_quality{
  display: flex;
  justify-content: center;
}

.portfolio_quality > a {
  text-decoration: none;
}

.social_icon_container{
  display: flex;
  justify-content: space-around;
}

.contact_me_forms{
  display:grid;
  grid-template-rows: auto;
}

.send_email{
  width: 100%;
  color: gray;
  font-size:1rem;
  
}